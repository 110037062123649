import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress,
  Button
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";
import CreditCardForm from "../../MyAccount/CreditCardForm";
import { addCoupon, validatePromoCode } from "../../../redux/actions/userActions";
import {
  FULFILLED,
  PENDING,
  REJECTED,
} from "../../../redux/constants/actionStatusConstants";
import useStatus from "../../../hooks/useStatus";
import { useCreditCard } from "../../../hooks/useSession";
import useDebounce from "../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  contactInformationBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  contactInformationForm: {
    width: "34%",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactInformationFormRow: {
    display: "flex",
    marginBottom: "1rem",
  },
  contactInformationFormRowInput: {
    marginRight: "1rem !important",
  },
  cardForm: {
    width: "40%",
    minWidth: "25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  giftCardForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  stripeInput: {
    border: "solid 1px #e8e8e8",
    padding: "1rem 0.85rem",
    marginBottom: "0.5rem",
    borderRadius: "0.3rem",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
    "&:hover": {
      border: "solid 1px #002653",
    },
  },
  giftInputContainer: {
    position: "relative",
  },
  doneIcon: {
    position: "absolute",
    top: "0.6rem",
    right: "-0.5rem",
  },
  spinner: {
    position: "absolute",
    top: "0.8rem",
    right: "-0.3rem",
  },
  couponError: {
    display: "flex",
  },
}));

const stripeLoaded = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentInformation = ({ expanded, handleChange, setExpanded, setGlobalValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status } = useStatus(addCoupon);
  const promoCodeStatus = useStatus(validatePromoCode);
  const { creditCard } = useCreditCard();
  const [giftLog, setGiftLog] = useState({
    hasError: false,
    log: 'empty'
  });
  const [promoCodeLog, setPromoCodeLog] = useState({
    hasError: false,
    log: 'empty'
  });
  const [giftCode, setGiftCode] = useState('');
  const debouncedGiftCode = useDebounce(giftCode, 500);
  const [promoCode, setPromoCode] = useState('');
  const debouncedPromoCode = useDebounce(promoCode, 500);
  const [showContinue, setShowContinue] = useState(true);

  const handleGiftCodeChange = async (giftCode) => {
    if (giftCode !== '') {
      const response = await dispatch(addCoupon(giftCode));
      setGlobalValue("gift_up_coupon", '');
      if (response?.meta?.requestStatus === FULFILLED) {
        if (response.payload?.coupon?.remainingValue > 0) {
          setGiftLog({
            hasError: false,
            log: `Success! You've applied $${response.payload?.coupon?.remainingValue} toward your project`,
          });
          setGlobalValue("gift_up_coupon", giftCode);
        } else {
          setGiftLog({
            hasError: true,
            log: 'No balance remaining. Try another coupon.',
          });
        }
      } else if (response?.meta?.requestStatus === REJECTED) {
        setGiftLog({
          hasError: true,
          log: response.error?.message,
        });
      }
    } else {
      setGiftLog({
        hasError: false,
        log: 'empty',
      });
    }
  }

  const handlePromoCodeChange = async (code) => {
    if (code !== '') {
      const response = await dispatch(validatePromoCode(promoCode));
      const { meta: { requestStatus }, payload: { discountCoupon: { percentageDiscount } = {} } = {}, error } = response;

      setGlobalValue("discount_coupon_code", '');
      if (requestStatus === FULFILLED) {
        setPromoCodeLog({
          hasError: false,
          log: `Success! You've applied ${percentageDiscount}% off toward your project`,
        });
        setGlobalValue("discount_coupon_code", code);
      } else if (requestStatus === REJECTED) {
        setPromoCodeLog({
          hasError: true,
          log: error?.message,
        });
      }
      return;
    }
    setPromoCodeLog({
      hasError: false,
      log: 'empty',
    });
  }

  useEffect(() => {
    const timeOut = setTimeout(() => handlePromoCodeChange(promoCode), 2000);
    return () => clearTimeout(timeOut);
  }, [debouncedPromoCode]);

  useEffect(() => {
    const timeOut = setTimeout(() => handleGiftCodeChange(giftCode), 2000);
    return () => clearTimeout(timeOut);
  }, [debouncedGiftCode]);

  return (
    <Accordion
      expanded={expanded === "panel5"}
      onChange={handleChange("panel5")}>
      <AccordionSummary aria-controls="panel5a-content" id="panel5a-header">
        <Typography variant="title1">Payment Information</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.contactInformationBody}>
        <Typography variant="body1" style={{ width: "95%" }}>
          We collect your payment information now to ensure our firefighters
          will be paid if they accept your job. Don’t worry, you will only pay
          once your job is complete.
          <br /> Questions? Check out our{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://hidrent.com/faq")}>
            FAQs
          </b>{" "}
          to learn more about the payment process. <br />
          <br />
          All transactions are secure and encrypted.
        </Typography>
        <div className={classes.giftCardForm}>
          <Typography variant="title1">Gift Card</Typography>
          <div className={classes.giftInputContainer}>
            <input
              className={classes.stripeInput}
              value={giftCode}
              onChange={event => setGiftCode(event.target.value)}
              placeholder="Enter gift card code"
            />
            {status === PENDING && <CircularProgress
              size="1.5rem"
              className={classes.spinner}
            />}
            {!giftLog.hasError && giftLog.log !== 'empty' && status !== PENDING && <DoneIcon
              sx={{ width: "2rem", height: "auto" }}
              color="green"
              className={classes.doneIcon}
            />}
          </div>
          {giftLog.log !== 'empty' && <span className={classes.couponError}>
            <Typography variant={giftLog.hasError ? "error1" : "success1"}>{giftLog.log}</Typography>
          </span>}
          <Typography variant="title1">Promo Code</Typography>
          <div className={classes.giftInputContainer}>
            <input
              className={classes.stripeInput}
              value={promoCode}
              onChange={event => setPromoCode(event.target.value)}
              placeholder="Enter code"
            />
            {promoCodeStatus?.status === PENDING && <CircularProgress
              size="1.5rem"
              className={classes.spinner}
            />}
            {!promoCodeLog.hasError && promoCodeLog.log !== 'empty' && promoCodeStatus?.status !== PENDING && <DoneIcon
              sx={{ width: "2rem", height: "auto" }}
              color="green"
              className={classes.doneIcon}
            />}
          </div>
          {promoCodeLog.log !== 'empty' && <span className={classes.couponError}>
            <Typography variant={promoCodeLog.hasError ? "error1" : "success1"}>{promoCodeLog.log}</Typography>
          </span>}
        </div>
        <div className={classes.cardForm}>
          <Elements stripe={stripeLoaded}>
            <CreditCardForm setExpanded={setExpanded} setShowContinue={setShowContinue} isAtProjects={true} />
          </Elements>
        </div>
        {!!creditCard && showContinue && <Button
          variant={"contained"}
          color={"blue"}
          sx={{
            color: "white",
            fontFamily: "Urbanist-SemiBold",
            display: "flex",
            marginTop: "1.5rem",
            width: {
              xs: "100%",
              md: "11.9rem",
            },
          }}
          onClick={() => setExpanded("panel3")}
        >
          Continue
        </Button>}
        {/* <HidrentTextField
            variant="outlined"
            placeholder="Card Number"
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Lock sx={{ width: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            className={classes.contactInformationFormRowInput}
            {...register("cardNumber", {
              required: true,
            })}
            name="cardNumber"
            control={control}
            controller
            error={errors?.cardNumber}
            helperText={errors.cardNumber && "The card number is required."}
          />
          <HidrentTextField
            variant="outlined"
            placeholder="Name on card"
            sx={{ width: "100%", mt: "1rem", mb: "1rem" }}
            {...register("nameOnCard", {
              required: true,
            })}
            name="nameOnCard"
            control={control}
            controller
            error={errors?.nameOnCard}
            helperText={
              errors.nameOnCard && "The name on card field is required."
            }
          />
          <div className={classes.contactInformationFormRow}>
            <HidrentTextField
              variant="outlined"
              placeholder="Expiration date (MM / YY)"
              sx={{ width: "100%" }}
              className={classes.contactInformationFormRowInput}
              {...register("expirationDate", {
                required: true,
              })}
              name="expirationDate"
              control={control}
              controller
              error={errors?.expirationDate}
              helperText={
                errors.expirationDate && "The expritation date is required."
              }
            />
            <HidrentTextField
              variant="outlined"
              sx={{ width: "100%" }}
              placeholder="Security code"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Help sx={{ width: "1.2rem" }} />
                  </InputAdornment>
                ),
              }}
              {...register("securityCode", {
                required: true,
              })}
              name="securityCode"
              control={control}
              controller
              error={errors?.securityCode}
              helperText={
                errors.securityCode && "The security code is required."
              }
            />
          </div> */}
        {/* <Typography variant="title1">Billing Address</Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Same as project address"
              {...register("sameAsProjectAddress", {
                required: false,
              })}
              name="sameAsProjectAddress"
              controller
            />
          </FormGroup>
          <div className={classes.contactInformationFormRow}>
            <HidrentTextField
              variant="outlined"
              placeholder="First Name"
              sx={{ width: "100%" }}
              className={classes.contactInformationFormRowInput}
              {...register("firstNameBA", {
                required: true,
              })}
              name="firstNameBA"
              control={control}
              controller
              error={errors?.firstNameBA}
              helperText={errors.firstNameBA && "The first name is required."}
            />
            <HidrentTextField
              variant="outlined"
              sx={{ width: "100%" }}
              placeholder="Last Name"
              {...register("lastNameBA", {
                required: true,
              })}
              name="lastNameBA"
              control={control}
              controller
              error={errors?.lastNameBA}
              helperText={errors.lastNameBA && "The last name is required."}
            />
          </div>
          <HidrentTextField
            variant="outlined"
            sx={{ width: "100%" }}
            placeholder="Address"
            {...register("addressBA", {
              required: true,
            })}
            name="addressBA"
            control={control}
            controller
            error={errors?.addressBA}
            helperText={errors.addressBA && "The address is required."}
          />
          <HidrentTextField
            variant="outlined"
            sx={{ width: "100%", mt: "1rem", mb: "1rem" }}
            placeholder="City"
            {...register("cityBA", {
              required: true,
            })}
            name="cityBA"
            control={control}
            controller
            error={errors?.cityBA}
            helperText={errors.cityBA && "The city is required."}
          />
          <div className={classes.contactInformationFormRow}>
            <HidrentTextField
              variant="outlined"
              placeholder="State"
              sx={{ width: "100%" }}
              className={classes.contactInformationFormRowInput}
              {...register("stateBA", {
                required: true,
              })}
              name="stateBA"
              control={control}
              controller
              error={errors?.stateBA}
              helperText={errors.stateBA && "The state is required."}
            />
            <HidrentTextField
              variant="outlined"
              sx={{ width: "100%" }}
              placeholder="Zip Code"
              {...register("zipCodeBA", {
                required: true,
              })}
              name="zipCodeBA"
              control={control}
              controller
              error={errors?.zipCodeBA}
              helperText={errors.zipCodeBA && "The zip code is required."}
            />
          </div> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentInformation;
