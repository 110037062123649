import React from "react";
import { Typography, Card, CardContent, Chip } from "@mui/material";
import DefaultProject from "../../../assets/images/default-img.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    alignItems: " flex-start",
  },
  image: {
    width: "100%",
    height: "60%",
    objectFit: "cover",
    borderRadius: "10px 10px 0 0"
  },
}));

const chipColor = (status) => {
  if (status === "completed") {
    return "#43c66b";
  } else if (
    status === "pending" ||
    status === "assigned" ||
    status === "unassigned"
  ) {
    return "#2b8ced";
  } else if (status === "cancelled") {
    return "#fc454a";
  }
};

const ProjectCard = ({ onClick, project }) => {
  const classes = useStyles();
  const clickable = project?.status !== 'cancelled' && project?.status !== 'completed'
  return (
    <Card
      sx={{
        width: { md: "20rem", xs: "100%" },
        height: { md: "20rem", xs: "24rem" },
        marginRight: { md: "1.5rem", xs: "0" },
        marginBottom: { md: "1.5rem", xs: "1.5rem" },
        cursor: clickable ? "pointer" : "default",
      }}
      className={classes.card}
      onClick={onClick}
    >
      <img
        className={classes.image}
        src={project?.images[0]?.data || DefaultProject}
        alt=""
      />
      <Chip
        label={project?.status}
        sx={{
          backgroundColor: chipColor(project?.status),
          color: "#fff",
          textTransform: "capitalize",
          position: "absolute",
          top: "1rem",
          left: "0.8rem",
        }}
      />
      <CardContent sx={{ alignItems: "start" }}>
        <Typography variant="body4">
          {new Date(project?.createdAt).toLocaleDateString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </Typography>
        <Typography gutterBottom variant="title3" component="div">
          {project?.title}
        </Typography>
        <Typography variant="body3" color="#fa474f">
          {project?.service?.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
