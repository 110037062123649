import * as yup from "yup";

export const validationSchema = yup.object({
  title: yup.string().required("Title is a required field."),
  description: yup.string().required("Description is a required field."),
  equipmentDetails: yup.string().when("specialEquipment", {
    is: "yes",
    then: yup.string().required("Equipment Details is a required field."),
  }),
});
