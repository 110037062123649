/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import { useForm } from "react-hook-form";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import locationIcon from "../../../assets/icons/location.svg";
import LocationInput from "../../shared/LocationInput";
import { validationSchema } from "./validations.js";
import { useYupValidationResolver } from "../../../helpers/useValidationResolver";
import CheckIcon from "@mui/icons-material/Check";
import { useProjectDetails } from "../../../hooks/useProject";
import { useDispatch } from "react-redux";
import { verifyLocation } from "../../../redux/actions/projectActions";
import useStatus from "../../../hooks/useStatus";
import { useQueryParam, StringParam } from "use-query-params";

const useStyles = makeStyles((theme) => ({
  addressInputContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "125px",
      justifyContent: "space-between",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    width: 11.2,
    height: 16,
  },
  form: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  locationInput: {
    marginRight: "10px",
    width: "25.875rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
    },
  },
}));
const Location = ({ expanded, setExpanded, handleChange, setGlobalValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = React.useState("Continue");
  const [isDone, setIsDone] = React.useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const { projectDetails } = useProjectDetails();
  const [locationValue, setLocationValue] = React.useState(
    projectDetails?.address?.fullAddress || null
  );
  const [locationInputValue, setLocationInputValue] = React.useState("");
  const [location, setLocation] = useQueryParam(("location", StringParam));
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    register,
    setValue,
  } = useForm({
    resolver,
    defaultValues: {
      unitOrApt: projectDetails?.unitOrApt,
      addressAttributes: {
        fullAddress: projectDetails?.address?.fullAddress,
      },
    },
  });

  React.useEffect(() => {
    if (expanded === "panel1") {
      setIsDone(false);
    }
  }, [dispatch, expanded]);

  const onSubmit = (data) => {
    setExpanded("panel2");
    setIsDone(true);
    setGlobalValue(
      "addressAttributes.fullAddress",
      data?.addressAttributes?.fullAddress?.description ||
        data?.addressAttributes?.fullAddress
    );
    setGlobalValue("unitOrApt", data.unitOrApt);
  };

  return (
    <Accordion
      sx={{
        boxShadow:
          expanded === "panel1" ? "0 20px 30px 0 rgba(0, 0, 0, 0.1)" : "none",
      }}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}>
      <AccordionSummary>
        {(isDone && (
          <div
            className={classes.row}
            style={{ justifyContent: "space-between", width: "95%" }}>
            <div>
              <Typography variant="title1">Project Location</Typography>
              <div className={classes.row} style={{ alignItems: "center" }}>
                <img alt="" src={locationIcon} className={classes.icon} />
                <Typography variant="body1" color="#535353" marginLeft="9px">
                  {locationInputValue} {getValues("unitOrApt")}
                </Typography>
              </div>
            </div>
            <div className={classes.row} style={{ alignItems: "center" }}>
              <Button
                variant="text"
                sx={{
                  color: "#2b8ced",
                  fontFamily: "Urbanist-Medium",
                  display: "flex",
                  fontsize: 14,
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.43,
                  letterSpacing: "normal",
                  textAlign: "left",
                }}>
                Edit
              </Button>
              <CheckIcon color="green" />
            </div>
          </div>
        )) || <Typography variant="title1">Project Location</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.addressInputContainer}>
            <div className={classes.locationInput}>
              <LocationInput
                value={locationValue}
                setValue={setLocationValue}
                setLocation={setLocation}
                inputValue={locationInputValue}
                setInputValue={setLocationInputValue}
                formKey="addressAttributes.fullAddress"
                setFormValue={setValue}
                error={!!errors["addressAttributes.fullAddress"]}
                helperText={errors["addressAttributes.fullAddress"]?.message}
              />
            </div>
            <TextField
              name="unitOrApt"
              control={control}
              {...register("unitOrApt", {
                required: true,
                pattern:
                  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              })}
              controller
              type="number"
              variant="outlined"
              placeholder="Unit or Apt #"
              inputProps={{ min: "0" }}
              className={classes.unitTextField}
            />
          </div>
          <Button
            variant="contained"
            sx={{
              color: "white",
              fontFamily: "Urbanist-SemiBold",
              display: "flex",
              width: {
                xs: "100%",
                md: "11.9rem",
              },
              height: "3rem",
              mt: {
                xs: "1rem",
                md: "0",
              },
            }}
            color={(buttonText === "Continue" && "blue") || "red"}
            children={buttonText}
            type="submit"
          />
        </form>
      </AccordionDetails>
    </Accordion>
  );
};
Location.propTypes = {
  handleChange: PropTypes.func,
  expanded: PropTypes.string,
};
export default Location;
