/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ListItemText,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import options from "./menuOptions";

const MyAccountMenuList = ({
  selectedSection,
  setSelectedSection,
  section,
  setSection,
}) => {
  React.useEffect(() => {
    if (!section) {
      setSection("personal");
      setSelectedSection("personal");
    }
  }, []);

  return (
    <MenuList>
      {options.map((item, index) => (
        <MenuItem
          onClick={() => {
            setSection(item.section);
            setSelectedSection(item.section);
          }}
          key={index}
          sx={{ bgcolor: "white", mb: "1rem" }}>
          <ListItemIcon>
            {item.icon(
              selectedSection === item.section ? "#fa474f" : "#002653"
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={selectedSection === item.section ? "#fa474f" : "#002653"}
              variant="h5">
              {item.name}
            </Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MyAccountMenuList;
