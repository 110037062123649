/* eslint-disable no-unused-vars */
import { Button, Typography, Link, IconButton } from "@mui/material";
import { useRef } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import React from "react";
import ReactCodeInput from "react-verification-code-input";
import { useQueryParam, StringParam } from "use-query-params";
import {
  FULFILLED,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import { makeStyles } from "@mui/styles";
import VerifyEmail from "../../assets/icons/Verify by Email.svg";
import ResendCode from "../../assets/icons/Resend Verification Code.svg";
import {
  getUser,
  resendVerifyCode,
  verifyUser,
} from "../../redux/actions/userActions";
import { toast } from "react-toastify";
import useSession from "../../hooks/useSession";
import { useIntercom } from "react-use-intercom";
import TopBar from "../../components/shared/TopBar";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  bodyColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
    },
  },
  box: {
    border: "1px solid #d0d0d0",
    width: "536px",
    height: "58px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: "20px",
    },
  },
  bodyBox: {
    textDecoration: "underline",
    fontFamily: "Urbanist-Medium",
    fontSize: "0.75rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0.24,
    textAlign: "center",
    color: "#acacac",
    cursor: "pointer",
  },
  linkStyle: {
    textDecoration: "none",
    color: "#fa474f",
    fontFamily: "Urbanist-SemiBold",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    fontWeight: 600,
  },
}));

const VerifyAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputs = useRef([]);
  const { authenticated } = useSession();
  const { boot, show } = useIntercom();
  const [phoneNumber, setPhoneNumber] = useQueryParam(
    "phoneNumber",
    StringParam
  );
  const [verifyCode, setVerifyCode] = React.useState([]);
  const [isMobile, setDesktop] = React.useState(window.innerWidth < 600);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 600);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  if (!phoneNumber || !authenticated) {
    return <Navigate to={"/sign-up"} />;
  }

  const onSubmit = async () => {
    const response = await dispatch(
      verifyUser({ verificationCode: verifyCode })
    );
    if (response?.meta?.requestStatus === FULFILLED) {
      toast.success(
        <Typography color="success">Account verified successfully.</Typography>,
        {
          position: "top-center",
        }
      );
      dispatch(getUser());
      navigate(`/`);
    }
    if (response?.meta?.requestStatus === REJECTED) {
      toast.error(<Typography color="error">The code is invalid</Typography>, {
        position: "top-center",
      });
    }
  };

  const handleResend = () => {
    const response = dispatch(resendVerifyCode());
    if (response?.error) {
      toast.error(
        <Typography color="success">
          There was a problem, please try again.
        </Typography>,
        { position: "top-center" }
      );
    }
    toast.success(
      <Typography color="success">
        A new code verification has been sended
      </Typography>,
      { position: "top-center" }
    );
  };

  const handleOpenContactSupport = () => {
    boot();
    show();
  };

  const handleChange = (value) => {
    setVerifyCode(value);
  };

  return (
    <div>
      <TopBar />
      <div className={classes.bodyColumn}>
        <Typography variant="title5" sx={{ fontSize: "1.875rem" }}>
          Verify your account.
        </Typography>
        <Typography variant="h6">
          We sent a verification code to {phoneNumber}.
        </Typography>
        <Typography variant="h6">
          Enter the code below to verify your mobile phone number:
        </Typography>
        <ReactCodeInput
          onChange={handleChange}
          type="number"
          values={verifyCode}
          fields={6}
          autoFocus
        />
        <Button
          sx={{
            backgroundColor: "#fa474f",
            color: "white",
            width: { xs: "90%", sm: 259, md: 259 },
            height: 46,
            marginTop: "2.5rem",
            marginBottom: "1.563rem",
          }}
          onClick={() => onSubmit()}
        >
          Verify
        </Button>
        <Link
          className={classes.linkStyle}
          style={{
            marginBottom: "21px",
          }}
          onClick={() => handleResend()}
        >
          <img
            alt=""
            src={ResendCode}
            width="12.9px"
            style={{ marginRight: "8px" }}
          />
          Resend Verification Code
        </Link>
        {/* 
        <Link
          className={classes.linkStyle}
          style={{
            marginBottom: "98px",
          }}
        >
          <img
            alt=""
            src={VerifyEmail}
            width="12.9px"
            style={{ marginRight: "8px" }}
          />
          Verify by email
        </Link>
        */}
        <div className={classes.box}>
          <Typography
            width="414px"
            variant="body4"
            textAlign="center"
            lineHeight="1.5"
          >
            Your security is extremely important to us. We use this code to
            verify ownership of your mobile phone. Questions?{" "}
            <b
              className={classes.bodyBox}
              onClick={() => handleOpenContactSupport()}
            >
              Contact Support.
            </b>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
