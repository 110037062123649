import React from "react";
import { useNavigate, useParams } from "react-router";
import { makeStyles } from "@mui/styles";
import cancelProject from "../../assets/images/cancelProject.svg";
import HidrentButton from "../../components/shared/HidrentButton";
import Template from "../../components/shared/Template";
import { useDispatch } from "react-redux";
import { cancelJob } from "../../redux/actions/projectActions";
import { FULFILLED } from "../../redux/constants/actionStatusConstants";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  buttons: {
    width: 800,
    marginTop: 23,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const CancelProject = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [cancelReason, setCancelReason] = React.useState("already_completed");

  const handleCancel = async () => {
    const response = await dispatch(cancelJob({ id, cancelReason }));
    if (response?.meta?.requestStatus === FULFILLED) {
      toast.success(
        <Typography color="success">The project has been canceled.</Typography>,
        {
          position: "top-center",
        }
      );
      navigate("/project-cancelled");
    } else {
      toast.error(
        <Typography color="error">
          There has been an error cancelling your project. Please try again or
          contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };
  return (
    <Template
      title="Cancel Project"
      subtitle="Cancelling? We understand, just let us know why."
      button="Confirm & Cancel Project"
      children={
        <div className={classes.buttons}>
          <HidrentButton
            name="scheduling"
            state={cancelReason}
            setState={setCancelReason}
            children="Scheduling"
          />
          <HidrentButton
            name="already_completed"
            state={cancelReason}
            setState={setCancelReason}
            children="Already completed"
          />
          <HidrentButton
            name="communication"
            state={cancelReason}
            setState={setCancelReason}
            children="Communication"
          />
          <HidrentButton
            name="pricing"
            state={cancelReason}
            setState={setCancelReason}
            children="Pricing"
          />
          <HidrentButton
            name="found_help_elsewhere"
            state={cancelReason}
            setState={setCancelReason}
            children="Found help elsewhere"
          />
          <HidrentButton
            name="no_longer_needed"
            state={cancelReason}
            setState={setCancelReason}
            children="No longer needed"
          />
          <HidrentButton
            name="other"
            state={cancelReason}
            setState={setCancelReason}
            children="Other"
          />
        </div>
      }
      handleClick={() => handleCancel()}
      image={cancelProject}
    />
  );
};

export default CancelProject;
