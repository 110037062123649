import { Typography, Button, IconButton, Link } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import useSession from "../../hooks/useSession";
import hidrentIcon from "../../assets/icons/hidrent-pro-app.png";
import { useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/userActions";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#002653",
    display: "flex",
    flexDirection: "column",
  },
  topbar: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "space-between",
    alignSelf: "center",
  },
  text: {
    width: "90%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    bottom: 0,
    right: 0,
    left: 0,
    width: "90%",
    alignSelf: "center",
    marginTop: "3rem",
  },
}));

const MobileMenu = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authenticated } = useSession();
  const dispatch = useDispatch();
  return (
    <div className={classes.background}>
      <div className={classes.topbar}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => navigate("/")}
        >
          <img alt="" src={hidrentIcon} width="33px" />
        </IconButton>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
        >
          <MenuIcon sx={{ width: "2.5rem", height: "auto", color: "white" }} />
        </Button>
      </div>

      <div className={classes.text}>
        {(!authenticated && (
          <>
            <Typography variant="h1" textAlign="left" color="white">
              Services
            </Typography>
            <Typography variant="h1" color="white" textAlign="left" mt="10px">
              About
            </Typography>
            <Typography variant="h1" color="white" textAlign="left" mt="10px">
              Resources
            </Typography>
            <Typography
              variant="h1"
              color="white"
              textAlign="left"
              mt="10px"
              sx={{ cursor: "pointer" }}
              onClick={() => window.open("https://hidrent.com/faq")}
            >
              FAQs
            </Typography>
            <Typography variant="h9" mt="34px">
              Get Support
            </Typography>
            <Link
              onClick={() => {
                window.open("https://hidrent.com/terms-of-service");
              }}
              sx={{ textDecoration: "none", alignSelf: "start" }}
            >
              <Typography variant="h9">Terms of Use</Typography>
            </Link>
            <Link
              onClick={() => {
                window.open("https://hidrent.com/privacy-policy");
              }}
              sx={{ textDecoration: "none", alignSelf: "start" }}
            >
              <Typography variant="h9">Privacy Policy</Typography>
            </Link>

            <div className={classes.buttons}>
              <Button
                variant="outlined"
                sx={{ borderColor: "#fa474f", color: "white" }}
                onClick={() => navigate("/login")}
              >
                Log in
              </Button>
              <Button
                sx={{ backgroundColor: "#fa474f", color: "white", mt: "12px" }}
                onClick={() => navigate("/sign-up")}
              >
                Sign up
              </Button>
            </div>
          </>
        )) || (
          <>
            <Typography
              variant="h1"
              textAlign="left"
              color="white"
              onClick={() => navigate("/my-projects")}
            >
              Projects
            </Typography>
            <Typography
              variant="h1"
              color="white"
              textAlign="left"
              mt="10px"
              onClick={() => navigate("/notifications")}
            >
              Notifications
            </Typography>
            <Typography
              variant="h1"
              color="white"
              textAlign="left"
              mt="10px"
              onClick={() => navigate("/messages")}
            >
              Messages
            </Typography>
            <Typography
              variant="h1"
              color="white"
              textAlign="left"
              mt="10px"
              onClick={() => navigate("/my-account")}
            >
              Account
            </Typography>
            <Typography variant="h9" mt="34px">
              Get Support
            </Typography>
            <Link
              onClick={() => {
                window.open("https://hidrent.com/terms-of-service");
              }}
              sx={{ textDecoration: "none", alignSelf: "start" }}
            >
              <Typography variant="h9">Terms of Use</Typography>
            </Link>
            <Link
              onClick={() => {
                window.open("https://hidrent.com/privacy-policy");
              }}
              sx={{ textDecoration: "none", alignSelf: "start" }}
            >
              <Typography variant="h9">Privacy Policy</Typography>
            </Link>
            <div className={classes.buttons}>
              <Button
                sx={{ backgroundColor: "#fa474f", color: "white" }}
                onClick={() => navigate("/")}
              >
                Create a Project
              </Button>
              <Button
                sx={{ color: "white" }}
                onClick={() => dispatch(logout())}
              >
                Log Out
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
