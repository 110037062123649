import React from "react";
import { makeStyles } from "@mui/styles";
import TopBar from "../../components/shared/TopBar";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../redux/actions/userActions";
import useNotifications from "../../hooks/useNotifications";
import hidrentIcon from "../../assets/icons/hidrent-icon@2x.png";

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  notifications: {},
  actionButtons: {
    alignItems: "flex-start",
    display: "flex",
    paddingLeft: "6.5rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  notificationsBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications } = useNotifications();

  React.useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const handleOpenProject = (item) => {
    navigate(`/project-detail/${item.workableId}`);
  };

  return (
    <div className={classes.notifications}>
      <TopBar />
      <div className={classes.notificationsBody}>
        <Typography variant="h1">Notifications</Typography>
        <List sx={{ width: "100%" }}>
          {notifications?.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                padding: "1rem",
                backgroundColor: "#f7f7f7",
                borderRadius: "0.5rem",
                my: "1rem",
                cursor: "pointer",
              }}
              onClick={() => handleOpenProject(item)}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      textAlign: "start",
                      mb: "0.4rem",
                      lineHeight: "1.2rem",
                    }}
                    variant="h5"
                    color="#535353"
                  >
                    {item?.message}
                  </Typography>
                }
                secondary={
                  <Typography
                    sx={{ textAlign: "start", lineHeight: "2rem" }}
                    variant="body1"
                  >
                    {new Date(item?.createdAt).toLocaleDateString("default", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          <ListItem
            sx={{
              padding: "1rem",
              borderRadius: "0.5rem",
              my: "1rem",
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <img alt="" src={hidrentIcon} width="25.9px" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ ml: "0.5rem" }}
              primary={
                <Typography
                  sx={{
                    textAlign: "start",
                    mb: "0.4rem",
                    lineHeight: "1.3rem",
                  }}
                  variant="h5"
                  color="#535353"
                >
                  Welcome to Hidrent! Now you can hire a firefighter for your
                  next home project. Let’s get started:
                </Typography>
              }
              secondary={
                <Typography sx={{ textAlign: "start" }} variant="body1">
                  {new Date().toLocaleDateString("default", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </Typography>
              }
            />
          </ListItem>
          <div className={classes.actionButtons}>
            <Button
              sx={{ mr: "1rem", width: { md: "18rem", sm: "50%", xs: "90%" } }}
              variant="contained"
              color="red"
              onClick={() => navigate("/")}
            >
              Start a project!
            </Button>
            {/* 
            <Button
              sx={{ width: { md: "18rem", sm: "50%", xs: "50%" } }}
              variant="outlined"
              color="red"
            >
              How does it work?
            </Button>
            */}
          </div>
        </List>
      </div>
    </div>
  );
};

export default Notifications;
