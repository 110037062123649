import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { toast } from "react-toastify";
import HidrentTextField from "../../shared/HidrentTextField";
import { makeStyles } from "@mui/styles";
import HidrentPhoneInput from "../../shared/HidrentPhoneField";
// import VerifyEmail from "../../../assets/icons/Verify by Email.svg";
import ResendCode from "../../../assets/icons/Resend Verification Code.svg";
import { validationSchema } from "./validations";
import { useYupValidationResolver } from "../../../helpers/useValidationResolver";
import {
  getUser,
  resendVerifyCode,
  signUp,
} from "../../../redux/actions/userActions";
import {
  FULFILLED,
  REJECTED,
} from "../../../redux/constants/actionStatusConstants";
import useSession from "../../../hooks/useSession";
import { verifyUser } from "../../../redux/actions/userActions";
import "../../../pages/VerifyAccount/index.scss";

const useStyles = makeStyles((theme) => ({
  contactInformationBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  contactInformationForm: {
    width: "34%",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactInformationFormRow: {
    display: "flex",
    marginBottom: "1rem",
  },
  contactInformationFormRowInput: {
    marginRight: "1rem !important",
  },

  linkStyle: {
    textDecoration: "none",
    color: "#fa474f",
    fontFamily: "Urbanist-SemiBold",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    fontWeight: 600,
  },
  row: {
    display: "flex",
  },
}));

const ContactInformation = ({
  expanded,
  handleChange,
  setExpanded,
  showVerification,
  setShowVerification,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authenticated, user } = useSession();
  const [isDone, setIsDone] = React.useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const [verifyCode, setVerifyCode] = React.useState([]);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver });

  React.useEffect(() => {
    if (expanded === "panel4") {
      setIsDone(false);
    }
  }, [expanded]);

  const onSubmit = async (data) => {
    const response = await dispatch(signUp(data));
    if (response?.meta?.requestStatus === FULFILLED) {
      setShowVerification(true);
    }
    if (response?.meta?.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">{response?.error?.message}</Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  const onVerify = async () => {
    const response = await dispatch(
      verifyUser({ verificationCode: verifyCode })
    );
    if (response?.meta?.requestStatus === FULFILLED) {
      toast.success(
        <Typography color="success">Account verified successfully.</Typography>,
        {
          position: "top-center",
        }
      );
      dispatch(getUser());
      setExpanded("panel5");
    }
    if (response?.meta?.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">
          The code is invalid. Please try again.
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  const handleResend = () => {
    const response = dispatch(resendVerifyCode());
    if (response?.error) {
      toast.error(
        <Typography color="success">
          There was a problem, please try again.
        </Typography>,
        { position: "top-center" }
      );
      return;
    }
    toast.success(
      <Typography color="success">
        A new code verification has been sended
      </Typography>,
      { position: "top-center" }
    );
  };

  const handleCode = (value) => {
    setVerifyCode(value);
  };

  return (
    <Accordion
      expanded={expanded === "panel4"}
      onChange={handleChange("panel4")}>
      <AccordionSummary
        aria-controls="panel4a-content"
        id="panel4a-header"
        sx={{ height: isDone ? "144px" : "70px" }}>
        <Typography variant="title1">
          {" "}
          {showVerification || (authenticated && !!!user?.verifiedAt)
            ? "Verify your account"
            : "Contact Information"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.contactInformationBody}>
        <Typography variant="body1">
          {showVerification || (authenticated && !!!user?.verifiedAt)
            ? `We sent a verification code to ${getValues(
                "phoneNumber"
              )}. Enter the code below to verify your phone number`
            : "Create an account to manage your project and communicate with your firefighter."}
        </Typography>
        {((showVerification || (authenticated && !!!user?.verifiedAt)) && (
          <div style={{ marginTop: "1rem" }}>
            <ReactCodeInput
              onChange={handleCode}
              type="number"
              values={verifyCode}
              fields={6}
              autoFocus
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Button
                sx={{
                  backgroundColor: "#fa474f",
                  color: "white",
                  width: { xs: "90%", sm: 259, md: 259 },
                  height: 46,
                  marginTop: {
                    lg: "2.5rem",
                    md: "2.5rem",
                    sm: "2.5rem",
                    xs: "5rem",
                  },
                  marginBottom: "1.563rem",
                }}
                onClick={() => onVerify()}>
                Verify
              </Button>
              <Link
                className={classes.linkStyle}
                style={{
                  marginBottom: "21px",
                }}
                onClick={() => handleResend()}>
                <img
                  alt=""
                  src={ResendCode}
                  width="12.9px"
                  style={{ marginRight: "8px" }}
                />
                Resend Verification Code
              </Link>
              {/* 
              <Link
                className={classes.linkStyle}
                style={{
                  marginBottom: "98px",
                }}
                
              >
                <img
                  alt=""
                  src={VerifyEmail}
                  width="12.9px"
                  style={{ marginRight: "8px" }}
                />
                Verify by email
              </Link>
              */}
            </div>
          </div>
        )) || (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.contactInformationForm}>
            <div className={classes.contactInformationFormRow}>
              <HidrentTextField
                variant="outlined"
                placeholder="First Name*"
                sx={{ width: "100%" }}
                className={classes.contactInformationFormRowInput}
                name="firstName"
                control={control}
                controller
                error={!!errors?.firstName}
                helperText={errors?.firstName?.message}
              />
              <HidrentTextField
                variant="outlined"
                placeholder="Last Name*"
                sx={{ width: "100%" }}
                name="lastName"
                control={control}
                controller
                error={!!errors?.lastName}
                helperText={errors?.lastName?.message}
              />
            </div>
            <div className={classes.contactInformationFormRow}>
              <HidrentTextField
                variant="outlined"
                placeholder="Email*"
                type="email"
                sx={{ width: "100%" }}
                className={classes.contactInformationFormRowInput}
                name="email"
                control={control}
                controller
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
              <HidrentPhoneInput
                name="phoneNumber"
                control={control}
                controller
                id="phone-input"
                placeholder="Mobile Phone Number*"
                sx={{ width: "100%" }}
                error={!!errors?.phoneNumber}
                helperText={errors?.phoneNumber?.message}
              />
            </div>
            <HidrentTextField
              variant="outlined"
              sx={{ width: "100%" }}
              className={classes.contactInformationFormRowInput}
              placeholder="Password*"
              type="password"
              name="password"
              control={control}
              controller
              error={errors?.password}
              helperText={errors?.password?.message}
            />
            <Button
              color="blue"
              sx={{
                color: "white",
                width: { md: "11.9rem", xs: "100%" },
                mt: "1.5rem",
              }}
              variant="contained"
              type="submit">
              Continue
            </Button>
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ContactInformation;
