import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import TopBar from "../../components/shared/TopBar";
import useStatus from "../../hooks/useStatus";
import { makeStyles } from "@mui/styles";
import {
  forgotPassword,
  passwordCodeVerification,
} from "../../redux/actions/userActions";
import {
  PENDING,
  FULFILLED,
} from "../../redux/constants/actionStatusConstants";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  forgotPasswordBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "3rem",
    },
  },
  forgotPasswordForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    width: "25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const InsertPasswordCode = () => {
  const { status } = useStatus(forgotPassword);
  const classes = useStyles();
  const { email } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const response = await dispatch(
      passwordCodeVerification({
        email: email,
        code: data.code,
        account_confirmation: true,
      })
    );
    if (response.meta.requestStatus === FULFILLED) {
      toast.success(<Typography color="success">Perfect!</Typography>, {
        position: "top-center",
      });
      navigate(`/reset-password/${data.code}`);
    } else {
      toast.error(
        <Typography color="error">
          There was a problem, please try again
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };
  return (
    <div className={classes.signUp}>
      <TopBar />
      <div className={classes.forgotPasswordBody}>
        <Typography variant="h3">Insert your Code here</Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.forgotPasswordForm}
        >
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...register("code", {
                  required: true,
                })}
                {...field}
                variant="outlined"
                placeholder="Code"
                sx={{ width: "100%" }}
                error={errors.code}
                helperText={
                  errors.code?.type === "required" && "Code is required."
                }
              />
            )}
          />
          <Button
            type="submit"
            disabled={status === PENDING}
            sx={{ color: "white", mt: "2rem" }}
            variant="contained"
            color="red"
          >
            {(status === PENDING && (
              <CircularProgress
                sx={{
                  color: "#002653",
                  width: "1.5rem !important",
                  height: "1.5rem !important",
                }}
              />
            )) ||
              "Reset Password"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default InsertPasswordCode;
