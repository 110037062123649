import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const options = [
  {
    name: "Personal Info",
    icon: (color) => (
      <AccountCircleIcon
        style={{ color: color }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "personal",
  },
  // {
  //   name: "Account & Login",
  //   icon: (color) => (
  //     <SecurityIcon
  //       style={{ color: color }}
  //       fontSize="large"
  //       sx={{ marginRight: "0.8rem" }}
  //     />
  //   ),
  //   section: "account",
  // },
  {
    name: "Payment Methods",
    icon: (color) => (
      <CreditCardIcon
        style={{ color: color }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "payment",
  },
  {
    name: "Get Support",
    icon: (color) => (
      <HelpOutlineOutlinedIcon
        style={{ color: color }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "support",
  },
];

export default options;
